import _objectSpread from "/Volumes/workspace/project/twiboss/frontend/twi-super-admin/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { deletePermission, editPermission, getPermissionList } from '@/api/permission';
export default {
  name: "clientApiGroups",
  data: function data() {
    return {
      data: [],
      loading: false,
      formLabelWidth: '120px',
      dialogNewVisible: false,
      submitting: false,
      newForm: {
        id: 0,
        type: 2,
        path: "",
        name: "",
        parent_id: 0,
        sort: 0
      },
      rules: {
        path: [{
          required: true,
          message: '分组标识 必填'
        }],
        name: [{
          required: true,
          message: '分组名称 必填'
        }]
      }
    };
  },
  created: function created() {
    this.requestData();
  },
  methods: {
    requestData: function requestData() {
      var _this = this;

      this.loading = true;
      getPermissionList({
        type: 2,
        api_type: 1,
        page: -1,
        pageSize: -1
      }).then(function (res) {
        _this.loading = false;

        if (res.code === 0) {
          _this.data = res.data.list;
        }
      });
    },
    handleDelete: function handleDelete(node, data) {
      var _this2 = this;

      this.$confirm('您确定要删除这项数据吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        deletePermission({
          id: data.id
        }).then(function (res) {
          if (res.code === 0) {
            _this2.$message.success('删除成功');

            var parent = node.parent;
            var children = parent.data.children || parent.data;
            var index = children.findIndex(function (d) {
              return d.id === data.id;
            });
            children.splice(index, 1);
          }
        });
      }).catch(function () {
        _this2.$message.info('已取消删除');
      });
    },
    handleEdit: function handleEdit(node, data) {
      if (data) {
        this.newForm = {
          id: data.id,
          type: 2,
          path: data.path,
          name: data.name,
          parent_id: data.parent_id,
          sort: data.sort
        };
      } else {
        this.newForm = {
          id: 0,
          type: 2,
          path: "",
          name: "",
          parent_id: 0,
          sort: 0
        };
      }

      this.dialogNewVisible = true;
    },
    handleSave: function handleSave(formName) {
      var _this3 = this;

      this.submitting = true;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var form = _objectSpread({}, _this3.newForm);

          editPermission(form).then(function (res) {
            _this3.submitting = false;

            if (res.code === 0) {
              _this3.$message.success('编辑成功');

              _this3.dialogNewVisible = false;

              _this3.requestData();
            }
          });
        } else {
          _this3.submitting = false;
        }
      });
    }
  }
};